import React, { useContext } from 'react'
import { ClientSettingContext } from './Context'
import { ClientSetting, clientSettings, getClientSettingByCookieLoginClientId } from './settings'
import { useConnectParams } from 'src/connect/useParams'

// 引数で与えられた固定のクライアント設定を提供します
export const StaticClientSettingProvider = ({
  client,
  children,
}: {
  client: ClientSetting
  children: React.ReactNode
}) => {
  return <ClientSettingContext.Provider value={client}>{children}</ClientSettingContext.Provider>
}

// URLパラメータに応じてクライアント設定を提供します
export const ClientSettingFromConnectParamsProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const params = useConnectParams()
  if (!params.paramsReady) {
    return null // TODO:
  }

  const clientId = params.params.cookieLogin?.clientId
  const theme =
    (clientId && getClientSettingByCookieLoginClientId(clientId)) || clientSettings.default

  return <ClientSettingContext.Provider value={theme}>{children}</ClientSettingContext.Provider>
}

export const useClientSetting = () => useContext(ClientSettingContext)
